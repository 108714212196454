
import { useStorage } from "vue3-storage";
import Api from '../service/api';
export default {
    auth(to, from, next){
        const storage = useStorage();

        var token = storage.getStorageSync("jwt"); 
        var api = new Api();


        api.verificaToken(token).then(data => {
       //   console.log(data.data.login)
            if(data.data.login){
                next();
            }
          //  autorizado = dat
        }).catch(function () {
            next('/login');

          }); 
        //ajax para o backend para checar a validade do token
    }
}