<template>
	<div class="layout-topbar">
		<router-link to="/" class="layout-topbar-logo">
			<img alt="Logo" :src="topbarImage()" />
			<!-- <span>SAKAI</span> -->
		</router-link>
		<button class="p-link layout-menu-button layout-topbar-button" @click="onMenuToggle">
			<i class="pi pi-bars"></i>
		</button>

		<button class="p-link layout-topbar-menu-button layout-topbar-button"
			v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'scalein', 
			leaveToClass: 'hidden', leaveActiveClass: 'fadeout', hideOnOutsideClick: true}">
			<i class="pi pi-ellipsis-v"></i>
		</button>
		<ul class="layout-topbar-menu hidden lg:flex origin-top">
			<li>
				<button class="p-link layout-topbar-button">
					<i class="pi pi-bell"></i>
					<span>Events</span>
				</button>
			</li>
			<li>
				<button class="p-link layout-topbar-button"  @click="toggle" aria-haspopup="true" aria-controls="overlay_menu" >
					<i class="pi pi-cog"></i>
					<span>Configurações</span>
				</button>
				
				<Menu ref="menu" :model="items2" :popup="true" />
			</li>
			<!--
			<li>
				<button class="p-link layout-topbar-button">
					
					<SplitButton label="Save" icon="pi pi-plus" :model="items"></SplitButton>
					<span>Profile</span>
				</button>
			</li>
			-->
		</ul>
	</div>
</template>

<script>
import Api from './service/api';
export default {
	created() {
        this.token = this.$storage.getStorageSync("jwt")
		this.api = new Api();
    },
	methods: {
		toggle(event) {
			this.$refs.menu.toggle(event);
		},
        onMenuToggle(event) {
            this.$emit('menu-toggle', event);
        },
		onTopbarMenuToggle(event) {
            this.$emit('topbar-menu-toggle', event);
        },
		topbarImage() {
			return '/images/logo.png';
		}
    },
	computed: {
		darkTheme() {
			return this.$appState.darkTheme;
		}
	},
	data(){
		return {
			items2: [
				{
					label: 'Meu Perfil',
					icon: 'pi pi-external-link',
					command: () => {
						this.$router.push('/meu-perfil');
					}
				},
				{
					label: 'Empresas',
					icon: 'pi pi-external-link',
					command: () => {
						this.$router.push('/empresas');
					}
				},
				{
					label: 'Usuários',
					icon: 'pi pi-external-link',
					command: () => {
						this.$router.push('/usuarios');
					}
				},				
				{
					label: 'Logout',
					icon: 'pi pi-times',
					command: () => {
						this.$router.push('/login');
						this.api.logout(this.token).then(data => {
							this.$storage.removeStorageSync("jwt");
							this.$router.push('/login');
							console.log(data)
						});
					}
				}
			],
			items: [
				{
					label: 'Meu Perfil',
					icon: 'pi pi-external-link',
					command: () => {
						this.$router.push('/meu-perfil');
					}
				},
				{
					label: 'Empresas',
					icon: 'pi pi-external-link',
					command: () => {
						this.$router.push('/empresas');
					}
				},
				{
					label: 'Transportadoras',
					icon: 'pi pi-external-link',
					command: () => {
						this.$router.push('/transportadoras');
					}
				},				
				{
					label: 'Logout',
					icon: 'pi pi-times',
                    command: () => {
						this.$router.push('/login');
						this.api.logout(this.token).then(data => {
							this.$storage.removeStorageSync("jwt");
							this.$router.push('/login');
							console.log(data)
						}); 
					}
				}
			]
		}
	}
}
</script>