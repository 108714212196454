<template>
	<div>
		<div style="width:100%;text-align:center">
			<img :src="empresa.logo" style="width:100px;height:100px;border-radius: 50%; border: #ced4da solid 1px" >
			<p>{{nome}}</p>
			<select v-model="empresa_id" @change="carregarEmpresa">
				<option v-for="(empresa, index) in empresas" :key="index" :value="empresa.id">{{empresa.nome_fantasia}}</option>
			</select>
		</div>
		<div class="layout-menu-container">
			<AppSubmenu :items="model" class="layout-menu" :root="true" @menuitem-click="onMenuItemClick" @keydown="onKeyDown" />
		</div>
	</div>
</template>

<script>  
import AppSubmenu from './AppSubmenu';
import Api from './service/api';
import { useStorage } from "vue3-storage";
export default {
	created(){
		const storage = useStorage();
		this.nome = storage.getStorageSync("nome");
		this.user = storage.getStorageSync("user");
		this.token = this.$storage.getStorageSync("jwt")
		this.empresa = this.$storage.getStorageSync("empresa")
		this.empresa_id = this.empresa.id

		this.api = new Api();
		this.buscarEmpresasRepresentante();
	},
	data(){
		return{
			nome:'',
			empresas:[],
			empresa:null
		}
	},
	props: {
		model: Array
	},
    methods: {
		///empresas/por-representante/{representante_id}
		buscarEmpresasRepresentante(){
			this.api.empresasPorRepresentante(this.token,this.user.representante_id).then(data => {
				//empresas/por-representante/{representante_id}
				this.empresas = data.data.empresas
				this.loading = false;
			}); 
		},
		carregarEmpresa(event) {
			this.api.carregarEmpresa(this.token, this.empresa_id).then(data => {
				this.$storage.setStorageSync("empresa", data.data.resposta)
				this.$router.push('/');
			}).catch((error) => {
				if (error.response.status == 401) {
					alert(this.msg_fim_sessao);
					this.$router.push('/login');
				} else {
					alert(this.msg_erro);
				}
			});

			console.log(event.target.selectedOptions[0].value)
			console.log(event.target.selectedOptions[0].text)
			/*this.api.empresasPorRepresentante(this.token, this.user.representante_id).then(data => {
				//empresas/por-representante/{representante_id}
				this.empresas = data.data.empresas
				this.loading = false;
			});*/
		},
        onMenuItemClick(event) {
            this.$emit('menuitem-click', event);
        },
		onKeyDown(event) {
			const nodeElement = event.target;
			if (event.code === 'Enter' || event.code === 'Space') {
				nodeElement.click();
				event.preventDefault();
			}
		},
		bannerImage() {
			return this.$appState.darkTheme ? 'images/banner-primeblocks-dark.png' : 'images/banner-primeblocks.png';
		}
    },
	computed: {
		darkTheme() {
			return this.$appState.darkTheme;
		}
	},
	components: {
		'AppSubmenu': AppSubmenu
	}
}
</script>
<style scoped>
	select{
		background-color: transparent;
		border: #ced4da solid 1px;

	}
</style>