import axios from "axios";

const api = 'https://backendsellers.centrion.com.br/api/'

const api_skyhub = 'https://api.skyhub.com.br/'

const api_madeira = 'https://marketplace-sandbox.madeiramadeira.com.br/'

const api_viacep = 'https://viacep.com.br/ws/'

//const https = require('https');

export default class Api {

    login(email, password) {

        const headers = {
            'Accept': 'application/json'
        }
        return axios.post(api+'login', {
            email,
            password
            
        },{headers })
    }
    /*
    login(email, password) {
        var agent = new https.Agent({  
            rejectUnauthorized: false
        });
        const headers = {
            'Accept': 'application/json'
        }
        return axios.post(api+'login', {
            email,
            password
            
        },{headers, httpsAgent: agent })
    }*/

    logout(token){
        let headers = { "Authorization": "Bearer "+token }
        return axios.post(api+'logout',{},{headers})
    }

    verificaToken(token){
        let headers = { "Authorization": "Bearer "+token }
        return axios.get(api+'verifica-token',{headers})   
    }

    //Carregar Empresa
    carregarEmpresa(token,empresa_id){
        let headers = { "Authorization": "Bearer "+token, "responseType":"blob" }
        return axios.get(api+'empresa/selecionar/'+empresa_id,{headers})
    }

    //Carregar Logo
    carregarLogoEmpresa(token,empresa_id){
        let headers = { "Authorization": "Bearer "+token, "responseType":"blob" }
        return axios.get(api+'empresa/selecionar-logo/'+empresa_id,{headers})
    }

    //Atualizar Empresa
    atualizarEmpresa(token,formData, empresa_id){
       // let headers = { "Content-Type":"multipart/form-data","Authorization": "Bearer "+token }
        let headers = { "Authorization": "Bearer "+token,"Content-Type": "multipart/form-data"}
        return axios.post(api+'empresa/alterar/'+empresa_id,formData,{headers})
    }

    //Atualizar Empresa
    salvarEmpresa(token,formData){
       // let headers = { "Content-Type":"multipart/form-data","Authorization": "Bearer "+token }
        let headers = { "Authorization": "Bearer "+token,"Content-Type": "multipart/form-data"}
        return axios.post(api+'empresa/salvar',formData,{headers})
    }
    //EnviarXML
    enviarXML(token,formData){
        // let headers = { "Content-Type":"multipart/form-data","Authorization": "Bearer "+token }
         let headers = { "Authorization": "Bearer "+token,"Content-Type": "multipart/form-data","responseType":"blob"}
         return axios.post(api+'carregar-xml',formData,{headers})
    }
    
    carregaCep(cep){
        return axios.get(api_viacep+cep+'/json')
    }

    cadastrarRepresentante(nome_representante, email_representante, endereco_representante,numero_representante,bairro_representante,cep_representante,cpf_representante,celular_representante,senha){
        return axios.post(api+'representante/cadastrar',{
            nome: nome_representante,
            email: email_representante,
            endereco: endereco_representante,
            numero:numero_representante,
            bairro:bairro_representante,
            cep:cep_representante,
            cpf:cpf_representante,
            celular:celular_representante,
            senha:senha
        })
    }
    salvarUsuario(token, usuario) {
        let headers = { "Authorization": "Bearer " + token }

        return axios.post(api + 'representante/cadastrar', {
            nome:            usuario.nome_usuario,
            cpf:             usuario. cpf_usuario,
            data_nascimento: usuario.data_nascimento,
            email:           usuario.email_usuario,
            senha:           usuario.senha_usuario,
            endereco:        usuario.logradouro_usuario,
            numero:          usuario.num_usuario,
            bairro:          usuario.bairro_usuario,
            cep:             usuario. cep_usuario
        },{headers})
    }

    cadastrarEmpresa(representante_id, nome_fantasia, cnpj,email,razao_social,telefone,atividade_empresa,endereco,numero,bairro,cep){
        return axios.post(api + 'empresa/cadastrar', {
            representante_id: representante_id,            
            nome_fantasia,
            razao_social,
            cnpj,
            email,
            telefone,
            atividade_empresa,
            endereco,
            numero,
            bairro,
            cep
        })
    }
    
    atualizarRepresentante({ usuario_id, token, nome, email, cpf, data_nascimento, endereco, bairro, numero, cep }){
        let headers = { "Authorization": "Bearer "+token }
        return axios.post(api+'representante/alterar/'+usuario_id,{
            nome,
            cpf,
            data_nascimento,
            email,
            endereco,
            bairro,
            numero,
            cep
        },{headers})
    }

    salvarRepresentante({ token, empresa_id, nome, cpf, email, senha, data_nascimento, endereco, bairro, numero, cep }){
        let headers = { "Authorization": "Bearer "+token }
        return axios.post(api + 'representante/cadastrar', {
            empresa_id,
            nome,
            cpf,
            email,
            senha,
            data_nascimento,
            endereco,
            bairro,
            numero,
            cep
        },{headers})
    }

    atualizarUsuario(token,representante_id,nome,email,password){
        let headers = { "Authorization": "Bearer "+token }
        return axios.post(api+'representante/alterar',{
            representante_id,
            nome,
            email,
            password
        },{headers})
    }

    

    //Carregar Representante
    carregarRepresentante(token,representante_id){
        let headers = { "Authorization": "Bearer "+token }
        return axios.get(api+'representante/selecionar/'+representante_id,{headers})
    }

    //Empresa por Representante
    empresasPorRepresentante(token,representante_id){
        let headers = { "Authorization": "Bearer "+token }
        return axios.get(api+'empresas/por-representante/'+representante_id,{headers})
    }

    //Area Atuacao
    listarAreasAtuacao(){
        return axios.get(api+'areas-atuacao')
    }

    //Transportadoras
    listarTransportadoras(token) {
        let headers = { "Authorization": "Bearer "+token }
        return axios.get(api+'transportadoras',{headers})
    }

    //Carregar Transportadora
    carregarTransportadora(token,transportadora_id){
        let headers = { "Authorization": "Bearer "+token }
        return axios.get(api+'transportadora/selecionar/'+transportadora_id,{headers})
    }

    //Salvar Transportadora
    salvarTrasportadora(token,nome,razao_social,cnpj,url){
        let headers = { "Authorization": "Bearer "+token }
        return axios.post(api+'transportadora/cadastrar', {
            nome,
            razao_social,
            cnpj,
            url
        },{headers})
    }

    //Update Perfil
    updatePerfil({ token, perfil}){
        let headers = { "Authorization": "Bearer "+token }
        return axios.post(api + 'perfil/alterar/' + perfil.id, {
            empresa_id: perfil.empresa_id,
            nome: perfil.nome,
            cpf: perfil.cpf,
            data_nascimento: perfil.data_nascimento,
            email: perfil.email,
            endereco: perfil.endereco,
            bairro: perfil.bairro,
            numero: perfil.numero,
            cep: perfil.cep,
            senha: perfil.senha
        },{headers})
    }

    //Atualizar Transportadora
    atualizarTransportadora(token,id,nome, razao_social,cnpj,url ){
        let headers = { "Authorization": "Bearer "+token }
        return axios.put(api+'transportadora/alterar/'+id, {
            nome,
            razao_social,
            cnpj,
            url
        },{headers})
    }

    //deletar Empresa
    deletarEmpresa(token,id){
        let headers = { "Authorization": "Bearer "+token }
        return axios.delete(api+'empresa/deletar/'+id,{headers})
    }

    //deletar Transportadora
    deletarTransportadora(token,id){
        let headers = { "Authorization": "Bearer "+token }
        return axios.delete(api+'transportadora/deletar/'+id,{headers})
    }

    //Salvar MetodoTransporte
    salvarMetodoTransporte(token,transportadora_id, nome){
        let headers = { "Authorization": "Bearer "+token }
        return axios.post(api+'metodo-transporte/cadastrar', {
            nome,
            transportadora_id
        },{headers})
    }

    //deletarMetodoTransporte
    deletarMetodoTransporte(token,id){
        let headers = { "Authorization": "Bearer "+token }
        return axios.delete(api+'metodo-transporte/deletar/'+id,{headers})
    }

    //listar Metodos Transporte Por Transportadoras
    listarMetodosTransportePorTransportadoras(token,transportadora_id){
        let headers = { "Authorization": "Bearer "+token }
        return axios.get(api+'metodos-transporte/por-transportadora/'+transportadora_id,{headers})
    }

    //Usuários
    listarUsuarios(token,empresa_id) {
        let headers = { "Authorization": "Bearer "+token }
        return axios.get(api+'representantes/'+empresa_id, {headers})
    }

    //Grupos
    listarGrupos(token,empresa_id, nPaginas = '1', qtdRegistros = '20', campoOrdenacao = 'id', tipoOrdenacao = 'DESC') {
        let headers = { "Authorization": "Bearer "+token }
        return axios.post(api+'grupos/'+empresa_id, {
            campo_ordenacao: campoOrdenacao,
            tipo_ordenacao: tipoOrdenacao,
            n_pagina: nPaginas,
            qtd_registros: qtdRegistros
        },{headers})
    }
    carregarGrupo(token,grupo_id,empresa_id){
        let headers = { "Authorization": "Bearer "+token }
        return axios.get(api+'grupo/selecionar/'+empresa_id+'/'+grupo_id,{headers})
    }

    saveGrupo(token,nome_grupo, empresa_id){
        let headers = { "Authorization": "Bearer "+token }
        return axios.post(api+'grupo/cadastrar/'+empresa_id, {
            nome: nome_grupo
        },{headers})
    }

    updateGrupo(token,nome_grupo, grupo_id, empresa_id){
        let headers = { "Authorization": "Bearer "+token }
        return axios.put(api+'grupo/alterar/'+empresa_id+'/'+grupo_id, {
            nome: nome_grupo
        },{headers})
    }

    deletarGrupo(token,grupo_id, empresa_id){
        let headers = { "Authorization": "Bearer "+token }
        return axios.delete(api+'grupo/deletar/'+empresa_id+'/'+grupo_id,{headers})
    }
    //Salvar Categoria

    saveCategoria(token,nome_categoria, grupoId, empresa_id){
        let headers = { "Authorization": "Bearer "+token }
        return axios.post(api+'categoria/cadastrar/'+empresa_id, {
            nome: nome_categoria,
            grupo_id: grupoId
        },{headers})
    }
    updateCategoria(token,nome_categoria, categoriaId, empresa_id){
        let headers = { "Authorization": "Bearer "+token }
        return axios.put(api+'categoria/alterar/'+empresa_id+'/'+categoriaId, {
            nome: nome_categoria,
        },{headers})
    }

    listarCategorias(empresa_id) {
        return axios.get(api+'categorias/'+empresa_id, {

        })
    }

    selecionaMarketplaces(token,marketplace_id) {
        let headers = { "Authorization": "Bearer "+token }
        return axios.get(api+'marketplace/selecionar/'+marketplace_id,{headers})
    }

    listarCategoriasMarketplace(token,empresa_id,marketplace) {
        let headers = { "Authorization": "Bearer "+token }
        return axios.get(api+'categorias/com-marketplace/'+empresa_id+'/'+marketplace,{headers})
    }

    listarCategoriasPorGrupo(empresa_id,grupo_id) {
        return axios.get(api+'categorias/por-grupo/'+empresa_id+'/'+grupo_id, {
            
        })
    }

    deletarCategoria(token,categoria_id, empresa_id){
        let headers = { "Authorization": "Bearer "+token }
        return axios.delete(api+'categoria/deletar/'+empresa_id+'/'+categoria_id,{headers})
    }


    //Listar Produtos
    listarProdutos(token, nPaginas = '1', qtdRegistros = '20', campoOrdenacao = 'id', tipoOrdenacao = 'DESC', empresa_id) {
       /* var agent = new https.Agent({  
        rejectUnauthorized: false
        });*/
        let headers = { "Authorization": "Bearer "+token }
        return axios.post(api+'produtos/'+empresa_id, {
            campo_ordenacao: campoOrdenacao,
            tipo_ordenacao: tipoOrdenacao,
            n_pagina: nPaginas,
            qtd_registros: qtdRegistros
        },{headers}/*,{ httpsAgent: agent }*/)
    }

    //Listar Produtos
	listarProdutosAtivos(token, empresa_id) {
        let headers = { "Authorization": "Bearer "+token }
        return axios.get(api+'produtos-ativos/'+empresa_id,{headers})
    }
    
    listaProdutosMaisVendidos(token,periodo,empresa_id) {
        let headers = { "Authorization": "Bearer "+token }
        return axios.get(api+'produtos/mais-vendidos/'+periodo+'/'+empresa_id,{headers})
    }

    carregarProduto(token,produto_id,empresa_id){
        let headers = { "Authorization": "Bearer "+token }
        return axios.get(api+'produto/selecionar/'+empresa_id+'/'+produto_id,{headers})
    }

    selecionaProdutoPorSKU(token,sku,empresa_id){
        let headers = { "Authorization": "Bearer "+token }
        return axios.get(api+'produto/selecionar/por-sku/'+empresa_id+'/'+sku,{headers})
    }

    vincularVariosAnunciosProduto({ token, anuncios, produto_id, empresa_id }) {
        let headers = { "Authorization": "Bearer " + token }
        return axios.post(api+'anuncios/vincular-multiplos-produto/'+empresa_id, {
            anuncios,
            produto_id
        },{headers})
    }
    cadastrarProdutoVincularAnuncios({ token, anuncios, anuncio_pai, empresa_id }) {
        let headers = { "Authorization": "Bearer " + token }
        return axios.post(api+'anuncios/cadastrar-produto-vincular-anuncios/'+empresa_id, {
            anuncios,
            anuncio_pai
        },{headers})
    }

    qtdProdutos(token,empresa_id){
        let headers = { "Authorization": "Bearer "+token }
        return axios.get(api + 'produtos/quantidade/'+empresa_id,{headers})
    }

    excluirProduto(token,produto_id,empresa_id){
        let headers = { "Authorization": "Bearer "+token }
        return axios.delete(api+'produto/deletar/'+empresa_id+'/'+produto_id,{headers})
    }

    saveProduto(token,produto, empresaId) {
        let headers = { "Authorization": "Bearer "+token }
        return axios.post(api+'produto/cadastrar/'+empresaId, {
            empresa_id: empresaId,
            nome: produto.nome,
            descricao: produto.descricao,
            sku: produto.sku,
            ean: produto.ean,
            marca_id: produto.marca_id,
            preco_normal: produto.preco_normal,            
            preco_desconto: produto.preco_desconto,
            altura: produto.altura,
            largura: produto.largura,
            profundidade: produto.profundidade,
            peso: produto.peso,
            estoque: produto.estoque,
            video: produto.video,
            tipo_cadastro: produto.tipo_cadastro,
            status: produto.status,
            
        }, { headers })
    }

	updateProduto(token,produto, empresaId){
        let headers = { "Authorization": "Bearer "+token }
        return axios.put(api+'produto/alterar/'+empresaId, {
            id: produto.id,
            nome: produto.nome,
            descricao: produto.descricao,
            sku: produto.sku,
            ean: produto.ean,
            ncm: produto.ncm,
            marca_id: produto.marca_id,
            preco_normal: produto.preco_normal,
            preco_desconto: produto.preco_desconto,
            altura: produto.altura,
            largura: produto.largura,
            profundidade: produto.profundidade,
            peso: produto.peso,
            estoque: produto.estoque,
            video: produto.video,
            tipo_cadastro: produto.tipo_cadastro,
            status:produto.status
        },{headers})
    }

    salvarImagemProduto(token,empresaId,dataForm){
        let headers = { "Authorization": "Bearer "+token,"Content-Type": "multipart/form-data"}
        return axios.post(api+'produto/salvar-imagem/'+empresaId,dataForm,{headers})
    }

    salvarTabelaPrecosProduto(produto_id,preco,empresaId){
        return axios.post(api+'produto/salvar-tabela-precos/'+empresaId, {
            produto_id,
            preco
        })
    }

    updateListaPrecoProduto(token, array_precos,empresaId,produtoId){
        let headers = { "Authorization": "Bearer "+token}
        return axios.post(api+'produto/salvar-tabela-precos/'+produtoId+'/'+empresaId, {
            array_precos
        },{headers})
    }

    listaPrecosPorProduto(token,empresa_id,produto_id) {
        let headers = { "Authorization": "Bearer "+token}
        return axios.get(api + 'produto/tabela-precos/' + produto_id + '/' + empresa_id, { headers })
    }

    importarProdutosPlanilha(token,empresaId, formData){
        let headers = { "Authorization": "Bearer "+token,"Content-Type": "multipart/form-data"}
        return axios.post(api+'produto/importar/planilha/'+empresaId, formData,{headers})
    }

    importarProdutosAnuncios(token,empresaId,tipo_importacao,marketplace,integracao_id,sku_produto_importar){
        let headers = { "Authorization": "Bearer "+token}
        return axios.post(api+'produtos/importar-por-anuncio/'+empresaId,{
            tipo_importacao,
            marketplace,
            integracao_id,
            sku:sku_produto_importar
        },{headers})
    }
    
    
    salvarAtributoProduto(token,empresaId, produtoId, nome, valor) {
        let headers = { "Authorization": "Bearer "+token}
        return axios.post(api+'produto/cadastrar-atributo/'+empresaId,{
            produto_id:produtoId,
            nome_atributo: nome,
            valor_atributo:valor
        },{headers})
    }

    deletarAtributo(empresaId, atributoId){
        return axios.post(api+'produto/atributo/deletar/'+empresaId+'/'+atributoId,{
        })
    }

    importarAnuncioPorProduto(token,empresa_id,produtos){

        let headers = { "Authorization": "Bearer "+token }
        return axios.post(api+'anuncio/importar-por-produtos/'+empresa_id,
        {
            produtos
        },{headers})
    }

    cargaAnuncios(token,empresa_id, integracao_id, nome_marketplace,tipo_importacao,sku_importacao){
        console.log("integracao_id: "+integracao_id)
        let headers = { "Authorization": "Bearer "+token }
        return axios.post(api+'anuncios/carga/'+empresa_id,
        {
            integracao_id,
            marketplace:nome_marketplace,
            tipo_importacao,
            sku:sku_importacao
        },{headers})
    }

    listarAnuncios(token,empresa_id, integracao_id, nPaginas = '1', qtdRegistros = '20', campoOrdenacao = 'id', tipoOrdenacao = 'DESC') {
        let headers = { "Authorization": "Bearer "+token }
        return axios.post(api+'anuncios/marketplace/'+empresa_id, {
            integracao_id: integracao_id,
            campo_ordenacao: campoOrdenacao,
            tipo_ordenacao: tipoOrdenacao,
            n_pagina: nPaginas,
            qtd_registros: qtdRegistros
        },{headers})
    }

    buscarAnuncioPorSKU({ token, empresa_id, integracao_id, sku_busca }){
        let headers = { "Authorization": "Bearer "+token }
        return axios.post(api+'anuncios/buscar-por-sku/'+empresa_id,
        {
            integracao_id,
            sku: sku_busca
        },{headers})
    }

    buscarAnuncioPorNome({ token, empresa_id, integracao_id, nome_busca }){
        let headers = { "Authorization": "Bearer "+token }
        return axios.post(api+'anuncios/buscar-por-nome/'+empresa_id,
        {
            integracao_id,
            nome: nome_busca
        },{headers})
    }
    
    listarAnunciosPorProduto(token,produto_id,empresa_id) {
        let headers = { "Authorization": "Bearer "+token }
        return axios.get(api+'anuncios/'+empresa_id+'/'+produto_id,{headers})
    }

    excluirAnuncio(token,anuncio_id,empresa_id){
        let headers = { "Authorization": "Bearer "+token }
        return axios.delete(api+'anuncio/deletar/'+empresa_id+'/'+anuncio_id,{headers})
    }

    carregarAnuncio(token,empresa_id,anuncio_id){
        let headers = { "Authorization": "Bearer "+token }
        return axios.get(api+'anuncio/selecionar/'+empresa_id+'/'+anuncio_id,{headers})
    }

    listarMarketplaces(token) {
        let headers = { "Authorization": "Bearer "+token }
        return axios.get(api+'marketplaces',{headers})
    }

    listarMarketplacesIntegracao({token,empresa_id}) {
        let headers = { "Authorization": "Bearer "+token }
        return axios.get(api+'marketplaces/'+empresa_id,{headers})
    }

    listarIntegracoes(token,empresa_id){
        let headers = { "Authorization": "Bearer "+token }
        return axios.get(api+'integracao/'+empresa_id,{headers})
    }

    qtdPedidos(token,empresa_id){
        let headers = { "Authorization": "Bearer "+token }
        return axios.get(api+'pedidos/quantidade/'+empresa_id,{headers})
    }

    qtdPedidosPorStatus(token,status,empresa_id){
        let headers = { "Authorization": "Bearer "+token }
        return axios.get(api+'pedidos/qtd-por-status/'+status+'/'+empresa_id,{headers})
    }

    qtdPedidosPorStatusEsteMes(token,status,empresa_id){
        let headers = { "Authorization": "Bearer "+token }
        return axios.get(api+'pedidos/qtd-por-status/mes'+status+'/'+empresa_id,{headers})
    }

    qtdPedidosPorStatusUltimosDias(token,status,empresa_id,dias){
        let headers = { "Authorization": "Bearer "+token }
        return axios.get(api+'pedidos/qtd-por-status/ultimos-dias/'+status+'/'+dias+'/'+empresa_id,{headers})
    }

    quantidadePedidosNovos(token,empresa_id){
        let headers = { "Authorization": "Bearer "+token }
        return axios.get(api+'pedido/qtd-novos/'+empresa_id,{headers})
    }

    criarMarketplaceEmpresa(token_sistema,empresa_id,marketplace_id, nome, usuario, token, cnpj){
        let headers = { "Authorization": "Bearer "+token_sistema }
        return axios.post(api+'integracao/cadastrar/'+empresa_id,{
            marketplace_id,
            nome,
            usuario,
            token,
            cnpj
        },{headers})
    }

    alterarMarketplaceEmpresa({
        token,
        empresa_id,
        nome,
        usuario,
        access_token,
        cnpj,
        client_id_mercadolivre,
        client_secret_mercadolivre,
        marketplace_id,
        token_sistema }) {
        let headers = { "Authorization": "Bearer "+token_sistema }
        return axios.put(api+'integracao/alterar/'+empresa_id,{
            marketplace_id,
            nome,
            usuario,
            token,
            cnpj,
            client_id_mercadolivre,
            client_secret_mercadolivre,
            access_token
        },{headers})
    }

    redirectMercadoLivre(){
        return axios.get('https://auth.mercadolivre.com.br/authorization?response_type=code&client_id=4145132749873401&redirect_uri=http://localhost/login.php',{
            
        })
    }
    salvarCodeMercadoLivre(token,empresa_id,code){
        let headers = { "Authorization": "Bearer "+token }
        return axios.post(api+'salvar-code-mercado-livre/'+empresa_id,{
            code
        },{headers})
    }

    testeIntegracao({ token, empresa_id, marketplace_id }){
        let headers = { "Authorization": "Bearer "+token }
        return axios.post(api+'integracao/teste/'+empresa_id,{
            marketplace_id
        },{headers})
    }

    salvarAnuncioViaProduto(token, empresa_id, produto_id, marketplace_id, marketplace_nome, nome, sku, descricao, preco_normal, preco_desconto) {
            let headers = { "Authorization": "Bearer "+token }
        return axios.post(api+'anuncio/salvar-via-produto/'+empresa_id,{
            produto_id: produto_id,
            marketplace_id: marketplace_id,
            marketplace_nome: marketplace_nome,
            nome:nome,
            sku:sku,
            descricao:descricao,
            preco_normal:preco_normal,
            preco_desconto:preco_desconto
        },{headers})
    }

	saveAnuncio(token, anuncio, empresaId){
        let headers = { "Authorization": "Bearer "+token }
        return axios.post(api+'anuncio/cadastrar', {
            empresa_id: empresaId,
            integracao_id: anuncio.integracao_id,
            nome: anuncio.nome,
            descricao: anuncio.descricao,
            sku: anuncio.sku,
            ean: anuncio.ean,
            marca_id: anuncio.marca_id,
            preco_normal: anuncio.preco_normal,
            preco_desconto: anuncio.preco_desconto,
            altura: anuncio.altura,
            largura: anuncio.largura,
            profundidade: anuncio.profundidade,
            peso: anuncio.peso,
            status: anuncio.status,
            estoque: anuncio.estoque,
            video: anuncio.video,
            tipo_cadastro: anuncio.tipo_cadastro
        },{headers})
    }

	updateAnuncio(token,anuncio, empresaId){
        let headers = { "Authorization": "Bearer "+token }
        return axios.put(api+'anuncio/alterar/'+empresaId, {
            id: anuncio.id,
            nome: anuncio.nome,
            descricao: anuncio.descricao,
            sku: anuncio.sku,
            ean: anuncio.ean,
            marca_id: anuncio.marca_id,
            preco_normal: anuncio.preco_normal,
            preco_desconto: anuncio.preco_desconto,
            altura: anuncio.altura,
            largura: anuncio.largura,
            profundidade: anuncio.profundidade,
            peso: anuncio.peso,
            estoque: anuncio.estoque,
            video: anuncio.video,
            tipo_cadastro: anuncio.tipo_cadastro,
            status:anuncio.status
        },{headers})
    }

    qtdAnuncios(empresa_id){
        return axios.get(api+'anuncios/quantidade/'+empresa_id)
    }
    
    qtdAnunciosPorIntegracao(token,empresa_id,integracao){
        let headers = { "Authorization": "Bearer "+token }
        return axios.get(api+'anuncios/quantidade/por-marketplace/'+empresa_id+'/'+integracao,{headers})
    }

    salvarAtributoAnuncio(token,empresaId, anuncioId, nome, valor) {
        let headers = { "Authorization": "Bearer "+token }
        return axios.post(api+'anuncio/cadastrar-atributo/'+empresaId,{
            anuncio_id:anuncioId,
            nome_atributo: nome,
            valor_atributo: valor
        },{headers})
    }

    deletarAtributoPedido(empresaId, atributoId){
        return axios.post(api+'anuncio/atributo/deletar/'+empresaId+'/'+atributoId,{
        })
    }


	criarAnuncio(empresaId, produtoId, produtoEmpresaId, marcaId, nome, descricao, sku, ean, precoNormal, precoDesconto, altura, largura, profundidade, peso, estoque, video, tipoCadastro){
        return axios.post(api+'anuncio/cadastrar', {
            empresa_id: empresaId,
            produto_id: produtoId,
            produto_empresa_id: produtoEmpresaId,
            marca_id: marcaId,
            nome: nome,
            descricao: descricao,
            sku: sku,
            ean: ean,
            preco_normal: precoNormal,
            preco_desconto: precoDesconto,
            altura: altura,
            largura: largura,
            profundidade: profundidade,
            peso: peso,
            estoque: estoque,
            video: video,
            tipo_cadastro: tipoCadastro
        })
    }
    carregarIntegracao(token,empresa_id,id){       
        let headers = { "Authorization": "Bearer "+token } 
        return axios.get(api+'integracao/selecionar/'+empresa_id+'/'+id,{headers})
    }

    //Listar Pedidos
	listarPedidos(token,empresa_id,nPaginas = '1', qtdRegistros = '20', campoOrdenacao = 'id', tipoOrdenacao = 'DESC') {
        let headers = { "Authorization": "Bearer "+token }
        return axios.post(api+'pedidos/'+empresa_id, {
            campo_ordenacao: campoOrdenacao,
            tipo_ordenacao: tipoOrdenacao,
            n_pagina: nPaginas,
            qtd_registros: qtdRegistros
        },{headers})
    }

	listarPedidosPorStatus(token,empresa_id, status,nPaginas = '1', qtdRegistros = '20', campoOrdenacao = 'id', tipoOrdenacao = 'DESC') {
        let headers = { "Authorization": "Bearer "+token }
        return axios.post(api+'pedidos/por-status/'+empresa_id, {
            campo_ordenacao: campoOrdenacao,
            tipo_ordenacao: tipoOrdenacao,
            n_pagina: nPaginas,
            qtd_registros: qtdRegistros,
            status: status
        },{headers})
    }

    carregarPedido(token,pedido_id,empresa_id){
        let headers = { "Authorization": "Bearer "+token } 
        return axios.get(api+'pedido/selecionar/'+empresa_id+'/'+pedido_id,{headers})
    }

    //Status do Pedido
    alterarStatusPedidoFaturado(token,empresa_id,pedido_id, numero_nota_fiscal, chave_nota_fiscal, data_emissao_nota, hora_emissao_nota){
        let headers = { "Authorization": "Bearer "+token } 
        return axios.post(api+'pedido/alterar-status/'+empresa_id+'/'+pedido_id, {
            status:'FATURADO',
            numero_nota_fiscal,
            chave_nota_fiscal,
            data_emissao_nota,
            hora_emissao_nota
        },{headers})
    }

    alterarStatusPedidoTransportadora(token,empresa_id,pedido_id,codigo_rastreio,transportadora,metodo_envio,url, data_envio, hora_envio){
        let headers = { "Authorization": "Bearer "+token } 
        return axios.post(api+'pedido/alterar-status/'+empresa_id+'/'+pedido_id, {
            status: 'TRANSPORTADORA',
            codigo_rastreio,
            transportadora,
            metodo_envio,
            url,
            data_envio,
            hora_envio
        },{headers})
    }

    alterarStatusPedidoEntregue(token,empresa_id,pedido_id,data_entrega){
        let headers = { "Authorization": "Bearer "+token } 
        return axios.post(api+'pedido/alterar-status/'+empresa_id+'/'+pedido_id, {
            status: 'ENTREGUE',
            data_entrega
        },{headers})
    }

    alterarStatusPedidoCancelado(token,empresa_id,pedido_id){
        let headers = { "Authorization": "Bearer "+token } 
        return axios.post(api+'pedido/alterar-status/'+empresa_id+'/'+pedido_id, {
            status: 'CANCELADO',
        },{headers})
    }

    alterarStatusPedidoProblemaEntrega(token,empresa_id,pedido_id,data_ocorrencia, observacao_ocorrencia){
        let headers = { "Authorization": "Bearer "+token } 
        return axios.post(api+'pedido/alterar-status/'+empresa_id+'/'+pedido_id, {
            status: 'PROBLEMA_ENTREGA',
            data_ocorrencia,
            observacao_ocorrencia
        },{headers})
    }

    //Quantidades do dashboard
    qtdUltimosDias(token,empresa_id, periodo){
        let headers = { "Authorization": "Bearer "+token }
        return axios.get(api+'quantidade-ultimos-tempos/'+periodo+'/'+empresa_id,{headers})
    }


    //Pedidos
    importarNovosPedidos(token,empresa_id,marketplace){ 
        let headers = { "Authorization": "Bearer "+token }
        return axios.get(api+'pedidos/importar-novos/'+marketplace+'/'+empresa_id,{headers})
    }

    //Quantidade de Vendas no mes 
    qtdVendasMes(token,empresa_id){
        let headers = { "Authorization": "Bearer "+token }
        return axios.get(api+'quantidade-vendas-mes/'+empresa_id,{headers})
    }

    //Quantidade de Vendas no mes 
    ticketMedioMes(token,empresa_id){
        let headers = { "Authorization": "Bearer "+token }
        return axios.get(api+'ticket-medio-mes/'+empresa_id,{headers})
    }
        
    //Ticket Médio Ultimos dias
    ticketMedioUltimosDias(token,empresa_id,dias){
        let headers = { "Authorization": "Bearer "+token }
        return axios.get(api+'ticket-medio-vendas-ultimos-dias/'+dias+'/'+empresa_id,{headers})
    }

    //Quantidade de Vendas no mes 
    faturamentoMes(token,empresa_id){
        let headers = { "Authorization": "Bearer "+token }
        return axios.get(api+'faturamento-mes/'+empresa_id,{headers})
    }

    //Quantidade de Vendas no mes 
    qtdProdutosVendidosMes(token,empresa_id){
        let headers = { "Authorization": "Bearer "+token }
        return axios.get(api+'quantidade-produtos-vendidos-mes/'+empresa_id,{headers})
    }

    //Quantidade de Vendas nos ultimos dias selecionados
    qtdProdutosVendidosUltimosDias(token,empresa_id,dias){
        let headers = { "Authorization": "Bearer "+token }
        return axios.get(api+'quantidade-produtos-vendidos-ultimos-dias/'+dias+'/'+empresa_id,{headers})
    }

    cadastrarCategoriaMarketplace(empresaId,codigoMarketplace, marketplaceId,categoriaId){
        const headers = {
            'X-User-Email': 'marcelo_zandonadi@hotmail.com',
            'x-Api-Key': 'zgntiTmfMComfx6toF6w',
            'x-accountmanager-key': 'KV3orbo8m7',
            'Accept': 'application/json',
            'Content-Type':'application/json'
        }
        return axios.post(api+'categoria/vincular-categoria-marketplace/'+empresaId,{
            codigo_marketplace: codigoMarketplace,
            marketplace_id: marketplaceId,
            categoria_id: categoriaId,
        },{headers})
    }


    //SKYHUB
    listarCategoriasSkyhub(){
        const headers = {
            'X-User-Email': 'marcelo_zandonadi@hotmail.com',
                'x-Api-Key': 'zgntiTmfMComfx6toF6w',
                'x-accountmanager-key': 'KV3orbo8m7',
                'Accept': 'application/json',
                'Content-Type':'application/json'
        }
        return axios.get(api_skyhub+'categories',{headers})
    }
    //MadeiraMadeira
    listarCategoriasMadeira(token){
        console.log(token)
        let headers = { "TOKENMM": token }//,"Access-Control-Allow-Origin":"*","Access-Control-Allow-Headers":"DNT,X-CustomHeader,Keep-Alive,User-Agent,X-Requested-With,If-Modified-Since,Cache-Control,Content-Type" }

        return axios.get(api_madeira + 'v1/categoria/limit=100000&offset=0',{headers})
    }

    buscarNovosPedidosSkyhub(){
        const headers = {
            'X-User-Email': 'marcelo_zandonadi@hotmail.com',
                'x-Api-Key': 'zgntiTmfMComfx6toF6w',
                'x-accountmanager-key': 'KV3orbo8m7',
                'Accept': 'application/json',
                'Content-Type':'application/json'
        }
        return axios.get(api_skyhub+'orders?filters[statuses][]=payment_received',{headers})
    }
}