import { createRouter, createWebHistory } from 'vue-router';
import App from './App.vue';
import Guard from './service/middleware';

const routes = [
    {
        path: '/',
        name: 'app',
        component: App,
        children: [
            {
                path: '',
                name: 'dashboard',
                component: () => import('./components/Dashboard.vue'),
                beforeEnter:Guard.auth
            },
            {
                path: '/importar-anuncios-produtos',
                name: 'importarAnunciosProdutos',
                component: () => import('./pages/ImportarAnuncios.vue'),
                beforeEnter:Guard.auth
            },
            {
                path: '/configuracao-inicial',
                name: 'configuracao_inicial',
                component: () => import('./pages/ConfiguracaoInicial.vue'),
                beforeEnter:Guard.auth
            },
            {
                path: '/mercado-livre',
                name: 'retorno_mercado_livre',
                component: () => import('./pages/RetornoMercadoLivre.vue'),
            },
            {
                path: '/importacao-produto-planilha',
                name: 'importacao_produto_planilha',
                component: () => import('./pages/ImportacaoProdutosViaPlanilha.vue'),
                beforeEnter:Guard.auth
            },
            {
                path: '/produtos',
                name: 'produtos',
                component: () => import('./pages/Produtos.vue'),
                beforeEnter:Guard.auth
            },
            {
                path: '/produtos',
                name: 'produtos',
                component: () => import('./pages/Produtos.vue'),
                beforeEnter:Guard.auth
            },
            {
                path: '/produto/:id',
                name: 'produto',
                component: () => import('./pages/Produto.vue'),
                beforeEnter:Guard.auth
            },
            {
                path: '/categorias',
                name: 'categorias',
                component: () => import('./pages/Categorias.vue'),
                beforeEnter:Guard.auth
            },
            {
                path: '/grupo/:id',
                name: 'grupo',
                component: () => import('./pages/Grupo.vue'),
                beforeEnter:Guard.auth
            },
            {
                path: '/cadastroprodutos',
                name: 'cadastro_produtos',
                component: () => import('./pages/Produto.vue'),
                beforeEnter:Guard.auth
            },
            {
                path: '/cadastrar-integracao',
                name: 'cadastro_integracao',
                component: () => import('./pages/CadastroIntegracao.vue'),
                beforeEnter:Guard.auth
            },
            {
                path: '/anuncios',
                name: 'anuncios',
                component: () => import('./pages/Anuncios.vue'),
                beforeEnter:Guard.auth
            },
            {
                path: '/anuncio/:integracao/:id',
                name: 'anuncio',
                component: () => import('./pages/Anuncio.vue'),
                beforeEnter:Guard.auth
            },
            {
                path: '/anuncios-marketplace/:nome_marketplace/:id_marketplace',
                name: 'anunciosMarketplace',
                component: () => import('./pages/AnunciosMarketplace.vue'),
                beforeEnter:Guard.auth
            },
            {
                path: '/cadastrar-anuncio/:integracao',
                name: 'cadastro_anuncios',
                component: () => import('./pages/Anuncio.vue'),
                beforeEnter:Guard.auth
            },
            {
                path: '/pedidos-venda',
                name: 'pedidosvenda',
                component: () => import('./pages/PedidosVenda.vue'),
                beforeEnter:Guard.auth
            },
            {
                path: '/pedido-venda/:id',
                name: 'pedidoVenda',
                component: () => import('./pages/PedidoVenda.vue'),
                beforeEnter:Guard.auth
            },
            {
                path: '/integracoes',
                name: 'integracoes',
                component: () => import('./pages/Integracoes.vue'),
                beforeEnter:Guard.auth
            },
            {
                path: '/marketplace/:id',
                name: 'marketplace',
                component: () => import('./pages/Marketplace.vue'),
                beforeEnter:Guard.auth
            },
            {
                path: '/meu-perfil',
                name: 'meuPerfil',
                component: () => import('./pages/Perfil.vue'),
                beforeEnter:Guard.auth
            },
            {
                path: '/adicionar-empresa',
                name: 'adicionarEmpresa',
                component: () => import('./pages/Empresa.vue'),
                beforeEnter:Guard.auth
            },
            {
                path: '/empresas',
                name: 'empresas',
                component: () => import('./pages/Empresas.vue'),
                beforeEnter:Guard.auth
            },
            {
                path: '/empresa/:id',
                name: 'empresa',
                component: () => import('./pages/Empresa.vue'),
                beforeEnter:Guard.auth
            },
            {
                path: '/usuarios',
                name: 'usuarios',
                component: () => import('./pages/Usuarios.vue'),
                beforeEnter:Guard.auth
            },
            {
                path: '/usuario/:id',
                name: 'usuario',
                component: () => import('./pages/Usuario.vue'),
                beforeEnter:Guard.auth
            },
            {
                path: '/cadastrar-usuario',
                name: 'cadastrarUsuario',
                component: () => import('./pages/Usuario.vue'),
                beforeEnter:Guard.auth
            },
            {
                path: '/adicionar-usuario',
                name: 'adiocionarUsuario',
                component: () => import('./pages/CadastrarUsuario.vue'),
                beforeEnter:Guard.auth
            },
            {
                path: '/transportadoras',
                name: 'transportadoras',
                component: () => import('./pages/Transportadoras.vue'),
                beforeEnter:Guard.auth
            },
        ]
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('./pages/Login.vue')
    },
    {
        path: '/cadastrar-empresa',
        name: 'cadastrarEmpresa',
        component: () => import('./pages/CadastrarEmpresa.vue')
    },
    
    {
        path: '/error',
        name: 'error',
        component: () => import('./pages/Error.vue')
    },
    {
        path: '/notfound',
        name: 'notfound',
        component: () => import('./pages/NotFound.vue')
    },
    {
        path: '/access',
        name: 'access',
        component: () => import('./pages/Access.vue')
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,

});

export default router;
